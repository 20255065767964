import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../context/LanguageContext';
import { projectsTranslation } from '../context/projectsTranslation'; 
import Slideshow from '../components/Slideshow';
import ProjectContainer from '../components/ProjectContainer';
import Footer from '../components/Footer';
import '../styles/Home.css';
import arrow from '../assets/arrow.png';

const Home = () => {

  const [showButton, setShowButton] = useState(false);
  const numberOfVisibleProjects = 3;
  const [visibleProjects, setVisibleProjects] = useState(numberOfVisibleProjects); // State to manage the number of visible projects
  const {language} = useContext(LanguageContext);

  const slideshowText = {
    en: {
      img1 : "Hello, I am Viktor Asenov, a.k.a. Rokyuto, and am happy that You are visiting my website. I am a System Programmer (as for now), working in the AI and Game Development area fields. In this page, You can find an overview of my project, developed so far. If you have an interest in reaching me, don't hesitate and use the ",
      img2: 'Won the "Programmer of the Alumni 19*" award.',
      img3: 'Photo from the development process of my graduation project - "Beat The Math" video game.',
    },
    bg: {
      img1: "Здравейте, аз съм Виктор Асенов, a.k.a. Рокюто и се радвам, че посещавате сайта ми. Аз съм системен програмист (засега), работещ в областите AI и разработка на игри. На тази страница можете да намерите общ преглед на моя проект, разработен до момента. Ако имате интерес да се свържете с мен, не се колебайте и използвайте",
      img2: 'Печеля наградата за програмист на випуска.',
      img3: 'Снимка от процеса на разработка на моя дипломен проект - видео игра "Победи Математиката".',
    }
  }

  const mainSlideshowImages = [
    {src: require('../assets/images/slideshow/IMG_9938.jpg'), text: <>{slideshowText[language].img1} <a href='/Contact'>{ language === 'en' 
      ? 'Contact page' : 'Контакт страницата'}</a>.</>},
    {src: require('../assets/images/slideshow/IMG_5151.jpg'), text: slideshowText[language].img2},
    {src: require('../assets/images/slideshow/nominations_programmer_course.jpg'), text: slideshowText[language].img3},
  ];

  const projectContainers = [
    {
      images: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/DrivingRecords/RecordDriving.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/DrivingRecords/past_record.mp4', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/DrivingRecords/records.mp4', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/DrivingRecords/cars.mp4', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/DrivingRecords/login.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/DrivingRecords/register.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/DrivingRecords/theme.mp4', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/DrivingRecords/home.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/DrivingRecords/home_1.png'],
      text: [projectsTranslation[language].DrivingRecords.title, projectsTranslation[language].DrivingRecords.description, projectsTranslation[language].DrivingRecords.development,  projectsTranslation[language].DrivingRecords.developedWith],
      icons: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/driving_records.png','https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/description.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/vehicle.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/kotlin_firebase.png', ''],
      href: '',
      date: projectsTranslation[language].DrivingRecords.completionDate
    },
    {
      images: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/recognition_en.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/recognition_bg.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/Recognition_LEV_EN.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/Recognition_LEV_BG.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/noRecognition_en.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/takePhoto_en.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/takePhoto_bg.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/processing_en.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/processing_bg.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/permission_en.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/permission_bg.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/home_en.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_BanknoteRecognizer/home_bg.png'],
      text: [projectsTranslation[language].AI_BanknoteRecognisionApp.title, projectsTranslation[language].AI_BanknoteRecognisionApp.description, projectsTranslation[language].AI_BanknoteRecognisionApp.development,  projectsTranslation[language].AI_BanknoteRecognisionApp.developedWith],
      icons: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/banknote.png','https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/description.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/banknote_recognition_mobile_app.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/python_typescript.png', ''],
      href: '',
      date: projectsTranslation[language].AI_BanknoteRecognisionApp.completionDate
    },
    {
      images: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_KPoP-Idols/Prediction_Rose.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_KPoP-Idols/Prediction_Yuna.png','https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_KPoP-Idols/Prediction_Lisa.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_KPoP-Idols/Prediction_Yoohyeon.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_KPoP-Idols/Prediction_Rose_2.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_KPoP-Idols/Prediction_Yuna_2.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_KPoP-Idols/Prediction_Lisa_2.png'],
      text: [projectsTranslation[language].AI_KPoPIdolsRecognition.title, projectsTranslation[language].AI_KPoPIdolsRecognition.description, projectsTranslation[language].AI_KPoPIdolsRecognition.development, projectsTranslation[language].AI_KPoPIdolsRecognition.developedWith],
      icons: ["https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/idol.png",'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/description.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/AI_Learning.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/python.png', ''],
      href: '',
      date: projectsTranslation[language].AI_KPoPIdolsRecognition.completionDate
    },
    {
      images: [ 'https://rokyuto-media-bucket.s3.amazonaws.com/AI_TicTacToe/AI_TicTacToe.png' , 'https://rokyuto-media-bucket.s3.amazonaws.com/AI_TicTacToe/AI_TicTacToe_Image.png' , 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/AI_TicTacToe/AI_TicTacToe_Video.mp4' ],
      text: [projectsTranslation[language].AI_TicTacToePlayer.title, projectsTranslation[language].AI_TicTacToePlayer.description, projectsTranslation[language].AI_TicTacToePlayer.development , projectsTranslation[language].AI_TicTacToePlayer.developedWith , projectsTranslation[language].AI_TicTacToePlayer.videoText],
      icons: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/artificial-intelligence.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/description.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/decision-making.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/python.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/youtube.png'],
      href: 'https://youtu.be/TYhGWYJv1D8',
      date: projectsTranslation[language].AI_TicTacToePlayer.completionDate
    },
    {
      images: [ 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/BeatTheMath/Thumbnail_DEMO.png' , 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/BeatTheMath/Market_Level.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/BeatTheMath/Game_Scoreboard.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/BeatTheMath/Game_Menu.png' ],
      text: [projectsTranslation[language].BeatTheMath.title, projectsTranslation[language].BeatTheMath.description, projectsTranslation[language].BeatTheMath.development , projectsTranslation[language].BeatTheMath.developedWith, projectsTranslation[language].BeatTheMath.videoText],
      icons: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/calculator.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/description.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/graduation-cap.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/game-development.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/youtube.png'],
      href: 'https://youtu.be/YQ7AE_r1rmE',
      date: projectsTranslation[language].BeatTheMath.completionDate
    },
    {
      images: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Space_Shooter_Game/Space_Shooter_Game_Thumbnail.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Space_Shooter_Game/SpaceShooterGame_Gameplay.mp4', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Space_Shooter_Game/Gameplay_Image.png' ],
      text: [projectsTranslation[language].SpaceShooterGame.title, projectsTranslation[language].SpaceShooterGame.description, <a href={require("../assets/pdf/Telerik_Academy_School_Certificate.pdf")} className='p'>{projectsTranslation[language].SpaceShooterGame.development}</a>, projectsTranslation[language].SpaceShooterGame.developedWith, projectsTranslation[language].SpaceShooterGame.videoText],
      icons: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/rocket.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/description.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/course.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/coding.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/youtube.png'],
      href: 'https://youtu.be/mdfBGiGdo_U',
      date: projectsTranslation[language].SpaceShooterGame.completionDate
    },
    {
      images: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/KPoP_Quiz/KPoP_Quiz.webp', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/KPoP_Quiz/Categories.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/KPoP_Quiz/Gameplay_0.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/KPoP_Quiz/Gameplay_1.png'],
      text: [projectsTranslation[language].KPoP_Quiz.title, projectsTranslation[language].KPoP_Quiz.description, projectsTranslation[language].KPoP_Quiz.development, projectsTranslation[language].KPoP_Quiz.developedWith, projectsTranslation[language].KPoP_Quiz.videoText],
      icons: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/kpop.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/description.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/main-idea.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/unity.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/youtube.png'],
      href: 'https://youtu.be/TKzBJIZGHK0',
      date: projectsTranslation[language].KPoP_Quiz.completionDate
    },
    {
      images: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Endless+Runner/Image_2.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Endless+Runner/EndlessRunner_Video.mp4', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Endless+Runner/Image_3.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Endless+Runner/Image_1.png'],
      text: [projectsTranslation[language].EndlessRunner.title, projectsTranslation[language].EndlessRunner.description, projectsTranslation[language].EndlessRunner.development, projectsTranslation[language].EndlessRunner.developedWith, projectsTranslation[language].EndlessRunner.videoText],
      icons: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/runner.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/description.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/main-idea.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/game-development(1).png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/youtube.png'],
      href: 'https://youtu.be/-GE0Ie7uvYw',
      date: projectsTranslation[language].EndlessRunner.completionDate
    },
    {
      images: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Infinte_Korean/Categories.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Infinte_Korean/Lesson.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Infinte_Korean/Transcription.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Infinte_Korean/Symbols.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Infinte_Korean/Translation.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Infinte_Korean/Incorrect_Answer.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/Infinte_Korean/Correct_Answer.png'],
      text: [projectsTranslation[language].InfiniteKorean.title, projectsTranslation[language].InfiniteKorean.description , projectsTranslation[language].InfiniteKorean.development , projectsTranslation[language].InfiniteKorean.developedWith , projectsTranslation[language].InfiniteKorean.videoText],
      icons: ['https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/south-korea.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/description.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/main-idea.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/c-sharp.png', 'https://rokyuto-media-bucket.s3.eu-north-1.amazonaws.com/icons/youtube.png'],
      href: 'https://youtu.be/aYGIaVUquhs?si=71Fqkpr4NT8LqVqv&t=1087',
      date: projectsTranslation[language].InfiniteKorean.completionDate
    }
  ];

  const handleShowMore = () => {
    setVisibleProjects(prevVisibleProjects => prevVisibleProjects + numberOfVisibleProjects);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='home'>
      <Slideshow images={mainSlideshowImages} />
      <h1>{ language === 'en' ? 'Overview of the projects I have developed so far' : 'Проектите, които съм разработил до този момент' }</h1>
      {projectContainers.slice(0,visibleProjects).map((project, index) => (
        <ProjectContainer key={index} media={project.images} text={project.text} icon={project.icons} href={project.href} bottomRightText={project.date} />
      ))}
      {visibleProjects < projectContainers.length && ( <button className="show-more" onClick={handleShowMore}>{ language === 'en' ? 'Show More' : 'Покажи още' } <img src={arrow} alt="arrow" className="arrow-icon" /></button> )}
      {showButton && ( <button className="back-to-top" onClick={scrollToTop}><img src={arrow} alt="Back to top" /></button> )}
      <Footer/>
    </div>
  );
};

export default Home;
